

/* main */
.SearchMain{
    padding: 32px 24px 32px 32px;
    background: #fff;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    width: auto;
}

.left-nav-bar{
    border: 1px solid #e4e4e4;
    /* border-bottom: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4; */
    color: #595959;
    background: #fafafa;
    font-weight: 500;
    display: inline-flex;
    padding: 0.85rem;
    border-radius: 0;
    white-space: nowrap;
    justify-content: space-between;
    line-height: 1.25;
    font-size: 1rem;
    width: 190px;
}
.search-main-right{
    width: 100%;
}
.search-header{
    display: flex;
    justify-content: space-between;
    margin: 0 8px 32px 24px;

}
.search-header>div:nth-child(1){
    padding: 0 8px 0 0;
    font-weight: 700;
    color: #343a40;
    font-size: 20px;
}

.search-header-form{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.search-header-form-div{
    left: 0;
    top: 0;
    margin: 0 0 8px;
    padding-left: 4px;
    padding-right: 8px;
    height: 36px;
    background: linear-gradient(270deg,hsla(0,0%,100%,0),#fff 3.27%);
}

.search-header-form-input-div{
    flex: 0 0 auto;
    width: auto;
}
.search-header-form-input-div>input{
    border-color: #f1f3f5;
    border-radius: 8px 0 0 8px;
    padding: 5px 8px;
    width: 222px;
    height: 36px;
    color: #343a40;
    font-size: .75rem;
    min-height: calc(2em + 0.5rem + 2px);
    border: 1px solid;
}
.search-header-form button{
    border-color: #f1f3f5;
    border-radius: 0 8px 8px 0;
    border-left: 0;
    padding: 8px 12px;
    height: unset;
    background-color: #f1f3f5;
    font-size: .75rem;
    line-height: 1.5;
    font-weight: 700;
    border: 1px solid;
}

.search-skill-bar{
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: flex;
    margin: 0 8px 0 20px;
    padding-top: 4px;
    padding-right: 36px;
    box-sizing: border-box;
    height: 48px;
    min-height: 48px;
}
.search-skill-bar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.courses__skill-tag{
    flex-shrink: 0;
    margin: 0 8px 8px 0;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 0 12px;
    height: 36px;
    background-color: #fff;
    font-size: 14px;
    color: #000;
    cursor: pointer;
}


.check-btn-back-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin: 0 8px 16px 24px;
}

.check-btn-div{
    display: flex;
    position: relative;
    width: 100%;
}
.check-btn-div-price{
    border: none;
    border-radius: 32px;
    padding: 9px 12px;
    background-color: #f1f3f5;
    color: #495057;
    font-size: 14px;
    line-height: 14px;
    margin-right: 10px;
}
.check-btn-div-score{
    display: flex;
    align-items: center;
    border-radius: 32px;
    border: 1px solid #ced4da;
    padding: 9px 12px;
    background-color: #fff;
    font-size: 14px;
    color: #000;
    line-height: 14px;
    margin-right: 10px;
}
.check-btn-select{
    border-radius: 8px;
    color: #212529;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: .75rem;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 2;
    border: 1px solid #ced4da;
}
.check-btn-select option{
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
}


.search-result-cards-back{
    width: 100%;
}


.search-result-cards-div{
    margin-left: 16px;
    display: flex;
}

.search-result-card{
    padding: 12px 8px;
    flex: none;
    width: 25%;
}

.card-img{
    width: 100%;
    height: 150px;
    position: relative;
  }
  .card-ribbon{
    position: absolute;
    padding: 0.125rem;
    top: 5%;
    right: 0;
    color: #fff;
    background: #ff6318;
    font-size: .875rem;
    font-weight: 700;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid #fff;
    border-right: none;
  }
  .card-img img{
    width: 100%;
    height: 100%;
  }
  .card-text{
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
    display: block;
  }

  .card-text .course_title{
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.5em;
    height: 3rem;
    font-size: 1rem;
    overflow: hidden;  		/* 을 사용해 영역을 감출 것 */ 
    text-overflow: ellipsis;  	/* 로 ... 을 만들기  */ 
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* white-space: nowrap; */  /*아래줄로 내려가는 것을 막기위해  */
  }
  .card-text .instructor{
    color: #7d7d7d;
    font-size: .9rem;
    line-height: 1.5;
  }


  .card-text .price{
    font-weight: 700;
    color: #175cbe;
    line-height: 1.5;
  }
  .rating_star{
    width: 4.5rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .rating_star svg{
    width: 0.9rem;
    height: 0.9rem;
  }
  
  
  .review_cnt{
    font-size: .5rem;
    color: #4a4a4a;
    position: relative;
    top:0.1rem;
    left: 0.8rem;
  }
  
  .tags{
    padding-top: 8px;
  }
  .tag{
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    color: #454545;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  


