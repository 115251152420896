
/* mentor.html */

/* .mentor-main-back{
    height: 1704px;
} */


/* header */
.mentor-header{
    background-color: #b9f1f1;
    width: 100%;
    height: 186px;
  }
  .heading_more svg{
    margin-left: 4px;
  }
  .mentor-header-container{
    position: relative;
    margin: auto;
    padding: 31px 2rem 38px;
    width: 100%;
    height: 100%;
    max-width: 1200px;
  }
  .mentor-header-img{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url(https://cdn.inflearn.com/public/mentors/mentors_banner_w.png);
    background-position: calc(100% + 80px) 30%;
    background-repeat: no-repeat;
  }
  .mentor-header-container > h1{
    font-size: 26px;
    font-weight: 700;
    line-height: 1.5;
    color: #1b1c1d;
    letter-spacing: -.3px;
  }
  
  .mentor-header-container > p{
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    color: #1b1c1d;
  }
  
  .mentor-header-container > span{
    margin-top: 10px;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    color: #175cbe;
  }
  
  /* main */
  .mentor-main{
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 2rem 0rem;
    display: flex;
  }
  
  /* left-panel */
  .mentor-left-panel{
    width: 180px;
    max-width: 180px;
  }
  .mentor-left-panel__content{
    position: sticky;
    top:84px;
    text-align: left;
  }
  .filter_btn{
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding: calc(0.375em - 1px) 0.75em;
    text-align: center;
    white-space: nowrap;
    border: 1px solid;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    display: none;
  }
  .mentor-left-panel__content-search{
    display: flex;
  }
  .control{
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
  }
  .control > input{
    height: 44px;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    box-shadow: none;
    color: #5f5f5f;
    max-width: 100%;
    width: 100%;
  }
  .control > button{
    height: 44px;
    background-color: #f5f5f5;
    border-color: #e4e4e4;
    border-width: 1px;
    border-radius: 4px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding: calc(0.375em - 1px) 0.75em;
    text-align: center;
    white-space: nowrap;
  
    border: 1px solid transparent;
    align-items: center;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
  }
  
  .left-panel-sort{
    width: 100%;
    height: 44px;
    margin-top: 1rem;
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: top;
  }
  .left-panel-sort >select{
    border-radius: 4px;
    height: 44px;
    width: 100%;
    border: 1px solid #e4e4e4;
    padding-right: 2.5em;
  
    background-color: #fff;
    color: #363636;
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none;
    padding: calc(0.375em - 1px) calc(0.625em - 1px);
  }
  
  .left-panel-checkbox{
    border: 1px solid #dedede;
    border-radius: 4px;
    margin-top: 1rem;
    text-align: left;
    color: #000a12;
  }
  .left-panel-checkbox-header{
    justify-content: flex-start;
    font-weight: 700;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    background: #f5f5f5;
    padding: 14px 24px;
    color: #595959;
    width: 100%;
    height: 50px;
    border-radius: 0;
    white-space: nowrap;
    display: inline-flex;
    line-height: 1.25;
    cursor: pointer;
    position: relative;
  }
  
  
  .left-panel-checkbox-body{
    padding: 0.75rem;
    color: #595959;
    background: #fff;
    border: 0;
    max-height: 150em;
    text-align: left;
    border-radius: 4px;
    line-height: 1.5;
  }
  .left-panel-checkbox-body-content{
    padding: 5px 0;
    cursor: pointer;
    color: #595959;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    text-decoration: none;
    background: #fff;
  }
  .left-panel-checkbox-body-content > div > input{
    margin-right: 3px;
  }
  
  
  
  .filter_reset{
    display: inline-block;
    margin: 18px 0 80px;
    padding: 0;
    font-size: 12px;
    font-weight: 800;
    background: none;
    border: 0;
    cursor: pointer;
  }
  
  
  
  
  .mentor-right-panel{
    width: 100%;
    max-width: 956px;
    height: 100%;
    padding: 0px 0px 0px 16px;
  }
  
  
  
  
  .mentor-cards{
    display: grid;
    grid-template-columns: repeat(4,1fr);    /* 컬럼 4등분 */
    gap: 16px;
  }
  
  .mentor-card{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 241px;
    height: 100%;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 8px 18px -5px rgb(23 39 75 / 5%);
    border: 1px solid #e9ebee;
    background-color: #fff;
    transition: transform .2s ease-in-out;
    cursor: pointer;
  }
  .mentor-card__top{
    position: relative;
    height: 100%;
  }
  .mentor-card__title{
    font-weight: 400;
    letter-spacing: -.3px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 4.5rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #1b1c1d;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .mentor-card__job-info-list div{
    position: relative;
    display: flex;
  }
  .mentor-card__job-info-list div dt{
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -.3px;
    font-size: 14px;
    color: #abb0b5;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .mentor-card__job-info-list div dd{
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -.3px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 1.5rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #858a8d;
  }
  
  .mentor-card__thumbnail{
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border: 1px solid #e9ebee;
    border-radius: 32px;
  }
  .mentor-card__thumbnail img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: block;
  }
  
  .mentor-card__bottom {
    flex-shrink: 0;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f1f3f5;
  }
  .mentor-card__bottom>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 28px;
  }
  .mentor-card__name {
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.5;
    max-height: 1.5rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #abb0b5;
    text-decoration: underline;
    cursor: pointer;
  }
  .mentor-card__rating {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 4px;
    padding: 4px 8px;
    max-width: 78px;
    color: #f9390f;
    text-align: center;
    border: 0;
    border-radius: 4px;
    background-color: rgba(255,210,77,.2);
    cursor: pointer;
  }
  
  
  .right-panel-paginate{
    margin-bottom: 80px;
    width: 100%;
  }
  .pages{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 18px;
    list-style: none;
    margin: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: #000a12;
  }
  .pages li{
    margin: 0;
    padding: 0;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
  }
  
  .pages li:nth-child(n+2){
    margin-left: 9px;
  }
  
  .pages li:nth-child(1) button{
    background-color: #1dc078;
    border: 1px solid #1dc078;
    color: #fff;
    padding: 5px 9px;
    border-radius: 3px;
    cursor: pointer;
    line-height: 1;
    font-size: 1rem;
  }
  .pages li:nth-child(n+2) button{
    border: 1px solid #dedede;
    color: #595959;
    padding: 5px 9px;
    border-radius: 3px;
    cursor: pointer;
    line-height: 1;
    font-size: 1rem;
  }

  
@media screen and (max-width:1024px){
  .mentor-cards{
    grid-template-columns: repeat(3,1fr);
  }
  .mentor-main{
    display: flex;
    flex-direction: column;
  }
  .left-panel-sort{
    display: none;
  }
  .left-panel-checkbox{
    display: none;
  }
  .filter_reset{
    display: none;
  }
  .mentor-left-panel__content{
    margin-left: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  .filter_btn{
    display: flex;
  }
  .mentor-left-panel{
    width: 100%;
    max-width: 100%;
  }

}








  