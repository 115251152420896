/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    font-size:1rem;
	line-height: 1.5;
    font-family: 'Pretendard-Regular';
    color: #454545;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
b, strong {
    font-weight: bolder;
}


/* 프리텐다드 */
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

a {
    text-decoration: none;   /* a tag 밑줄 없애기 */
    color: black;
}
a > span{
    color: #4a4a4a;
}

/* 내부 item 센터만들기 */
.item-center{
    display: flex;
    align-items: center; /* 수직 정렬 */
    flex-direction: row; /* default: row */
    justify-content: center; /* flex direction에 대해서 정렬방식 선택 */
}

/* font-awesome 오류 */
/* .fa,.fab,.fad,.fal,.far,.fas{
	font-weight: 600 !important;
} */


/* index.html */
/* main */

.div-start{
    width: 100%;
    border: solid black 1px;  
}


/* section */
.section__back{
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #f1f3f5;
}
.section__content{
    padding: 0px 32px 0px 32px;
    width: 1200px;
    height: 20px;
    margin: 0 auto;
}
.section__content li:nth-child(1){
    margin: 0px 2px 0px 0px;
}
.section__content li:nth-child(2){
    width: 1px;
    height: 12px;
    margin: 0px 8px;
    background: #d5dbe2
}


/* header */
.header{
  width: 100%;
  height: 64px;
  color: #4a4a4a;
  background-color: #ffffff;
  z-index: 72;
  position: sticky;
  top:0;
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 81% / 50%);
}
.header__content{
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0px 32px 0px 32px;
}
.header__logo{
  width: 140px;
  height: 100%;
  margin-right: 10px;
}
.header__menu{
  width: 100%;
  height: 100%;
  position: relative;
}

/* hover 색 변화 */
.header__menu > div:hover > a > span{
  color:#1dc078;
}


/* 첫번째 메뉴 보여주기 */
.header__menu-course{
  width: 180px;
  box-shadow: 8px 5px 8px 1px rgb(0 10 18 / 10%), 0 0 0 1px rgb(0 10 18 / 10%);
  padding: 0.5rem;
  color: #4a4a4a;
  background-color: #ffffff;
  line-height: 2;
  position: absolute;
  top:60px;
  left:10px;
  z-index: 74;
  display: none;
}
/* 첫번째 메뉴 보여주기 */
.header__menu > div:nth-child(1):hover > .header__menu-course{
  display: block;
}


/* 네번째 메뉴 보여주기 */
.header__menu-community{
  width: 180px;
  box-shadow: 8px 5px 8px 1px rgb(0 10 18 / 10%), 0 0 0 1px rgb(0 10 18 / 10%);
  padding: 0.5rem;
  color: #4a4a4a;
  background-color: #ffffff;
  line-height: 2;
  position: absolute;
  top:60px;
  left:200px;
  z-index: 74;
  display: none;
}
/* 네번째 메뉴 보여주기 */
.header__menu > div:nth-child(4):hover > .header__menu-community{
  display: block;
}

/* 다섯번째 메뉴 보여주기 */
.header__menu-inflearn{
  width: 180px;
  box-shadow: 8px 5px 8px 1px rgb(0 10 18 / 10%), 0 0 0 1px rgb(0 10 18 / 10%);
  padding: 0.5rem;
  color: #4a4a4a;
  background-color: #ffffff;
  line-height: 2;
  position: absolute;
  top:60px;
  left:210px;
  z-index: 74;
  display: none;
}
/* 네번째 메뉴 보여주기 */
.header__menu > div:nth-child(5):hover > .header__menu-inflearn{
  display: block;
}


.header__menu-search{
  padding: 8px;
  width: 164px;
  position: relative;
}
.header__menu-search input{
  width: 148px;
  height: 36px;
  padding: 5px 9px;
  border-radius: 3px;
  box-shadow: none;
  background: #f6f6f6;
  color: #5f5f5f;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 16px;
}
.header__menu-search input:hover{
  border-color: #dbdbdb;  
}
.header__menu-search input:focus{
  border-color: #d4d4d4;
}
.header__menu-search input div{
  width: 100%;
  height: 100%;
}

.header__menu-search .fa-search{
  position: absolute;
  right: 20px;
  top:24px;
}


.header__menu-cart{
  position: relative;
}
/* .header__menu-cart div{
  position: absolute;
  right: -10px;
  top:8px;
  color: white;
  background: red;
  border-radius: 20px;
  font-size: 14px;
  width: 21px;
  text-align: center;
  opacity: 0.9;
} */



.header__menu-btn1{
  border: 1px solid #dbdbdb;
  height: auto;
  line-height: 1;
  padding: 8px;
  margin-right: 8px;
  border-radius: 4px;
}
.header__menu-btn2{
  background-color: #ff7867;
  color: #fff;
  border: 1px solid #dbdbdb;
  height: auto;
  line-height: 1;
  padding: 8px;
  border-radius: 4px;
}


/* footer */
.footer{
  width: 100%;
  height: 438px;
  background: #303740;
  padding: 0;
  margin-top: 10px;
}
.footer-wrapper{
  display: flex;
  max-width: 1152px;
  margin: 0 auto;
  flex-direction: column;
  height: 100%;
}
.footer-top{
  display: flex;
  justify-content: space-between;
  padding: 32px 20px;
  border-bottom: 1px solid #444;
  height: 261px;
}
.footer-menu-list{
  margin-right: 20px;
}
.list_title{
  font-weight: 700;
  font-size: 14px;
  color: #eee;
  padding: 16px 0;
  line-height: 1.43;
}
.menu_sublist{
  display: flex;
  flex-direction: column;
}
.menu_sublist a{
  color: #bdbdbd;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 7px;
}

.footer-bottom{
  display: flex;
  height: 176px;
  padding: 32px 20px;
  justify-content: space-between;
}

.footer-bottom__left{
  display: flex;
  flex-direction: column;
}


#icon_brand_logo{
  font-size: 12px;
  color: #bdbdbd;
  line-height: 1.5;
  font-weight: 700;
}

.footer_info_top{
  margin-bottom: 8px;
}
.footer_info_top a{
  fill:#1dc078;
}



.footer_info_top span{
  display: inline-block;
  content: "|";
  color: #bdbdbd;
  margin: 0 2px;
  font-size: 12px;
}
.info-dropdown{
  max-height: 54px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
}

.footer-bottom__right{
  width: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}
.footer-bottom__right a{
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #bdbdbd;
  text-align: center;
  line-height: 35px;
}



/* footer */
.detail-footer{
  width: 100%;
  background: #303740;
  padding: 0;
  margin-top: 10px;
}


/* 로그인 모달창 */
.login-black-bg-modal {
  width : 100%;
  height : 100%;
  position : fixed;
  background : rgba(11,19,30,.37);
  z-index : 80;
  transition: all 1s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}
.login-white-bg-modal {
  background: white;
  border-radius: 6px;
  padding: 24px;
  margin: auto;
  width: 360px;
  height: 432px;
} 

.show-modal{
  visibility: visible;
  opacity: 1;
}


.login-close-btn{
  display: flex;
  align-items: center;
}
.login-close-btn svg{
  margin-left: auto;
  width: 16px;
  height: 12;
  cursor: pointer;
}

.login-logo{
  display: flex;
  align-items: center;
  margin: 12px 0 24px;
}
.brand_logo{
  width: 135px;
  height: 24px;
  margin: auto;
}
.brand_logo path{
  fill: #1dc078;
}

.form-control{
  margin-bottom: 12px;
  width: 312px;
  margin-top: 0;
  padding: 13px 12px;
  height: 48px;
  line-height: 1.47;
  font-size: 15px;
  border: 1px solid #dee2e6;
  letter-spacing: -.3px;
  border-radius: 4px;
  background-color: #fff;
}
.login-btn{
  border-color: #00c471;
  font-weight: 700;
  background-color: #00c471;
  margin: 12px 0;
  width: 312px;
  height: 48px;
  border: 1px solid;
  color: #fff;
  padding: 0 12px;
  line-height: 1.43;
  font-size: 14px;
  letter-spacing: -.3px;
}


.sign-in-modal__more-action{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.more-action__text{
  line-height: 1.38;
  letter-spacing: -.3px;
  font-size: 13px;
  font-weight: 400;
  color: #616568;
  border-bottom: 1px solid #858a8d;
  cursor: pointer;
}

.more-action-divider{
  margin: 0 8px;
  width: 1px;
  height: 10px;
  background-color: #858a8d;
  pointer-events: none;
}

.sign-in-modal__social-sign-in{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social-sign-in__line{
  position: relative;
  bottom: -8px;
  display: block;
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: #a0a0a0;
  border: none;
}
.social-sign-in__title{
  padding: 0 8px;
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -.3px;
  color: #abb0b5;
  z-index: 11;
  background-color: #fff;
}

.social__sign-in-buttons{
  display: flex;
  margin: auto;
  column-gap: 12px;
}

.social__button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}
.social__button--Kakao {
  background-color: #fae500;
}
.social__button--Google {
  background-color: #f8f8f8;
}
.social__button--Github {
  background-color: #3c4043;
}
.social__button--Apple {
  background-color: #fff;
}








