
/* detail.html */


.detail-top-description{
    padding: 40px 0;
    background: #002333;
  }
  .detail-top-container{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
    align-items: center;
  }
  
  .detail-top-left{
    display: inline-flex;
    justify-content: center;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
    padding:0px 8px 0px 42px;
  }
  .detail-top-thumbnail{
    position: relative;
    overflow: hidden;
    width: 440px;
    height: 286px;
    border-radius: 8px;
  }
  .detail-top-thumbnail img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .detail-top-right{
    padding: 0 42px 0 24px;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .detail-top-breadcrumb-label{
    background-color: #e5503c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 12px;
    margin-right: 10px;
    padding: 0 6px;
    height: 22px;
    color: #fff;
    font-weight: 700;
  }
  
  .detail-top-breadcrumb-el{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    white-space: pre;
  }
  .detail-top-title{
    line-height: 1.5;
    letter-spacing: -.3px;
    color: #fff;
    font-weight: 700;
    font-size: 26px;
  }
  .detail-top-cnt{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    margin-bottom: 8px;
    color: #fff;
  }
  
  .detail-top-name a{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    margin-right: 8px;
    color: #fff;
    text-decoration: underline;
  }
  
  .detail-tags a{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 12px;
    margin-right: 4px;
    padding: 4px 12px;
    border-radius: 100px;
    background-color: #173f51;
    color: #fff;
    font-weight: 500;
  }
  
  
  /* detail navbar */
  .detail-navbar{
    display: flex;
    align-items: flex-end;
    height: 45px;
    overflow-x: auto;
    padding: 0 54px;
    white-space: nowrap;
    margin: 0 auto;
    max-width: 1200px;
  }
  .detail-navbar span{
    margin-right: 30px;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    min-height: 33px;
    padding: 15px 0 6px;
    color: #abb0b5;
    border-bottom: 2px inset transparent;
  }
  .detail-navbar span a{
    color: #abb0b5;
  }
  .detail-navbar span:hover a{
    color: #414141;
  }
  
  
  /* detail main */
  .detail-main{
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
  }
  .detail-left-panel{
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .detail-left-panel-wrapper{
    padding: 28px 16px 0 34px;
    width: 100%;
    max-width: 720px;
  }
  
  
  .cd-body__title{
    margin-bottom: 16px;
    line-height: 1.45;
    letter-spacing: -.3px;
    color: #343a40;
    font-size: 22px;
  }
  .cd-body__description{
    margin-bottom: 8px;
    line-height: 1.69;
    color: #495057;
    font-size: 16px;
    letter-spacing: -.5px;
    font-weight: 500;
  }
  .cd-fixed-body{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 32px 24px 32px 16px;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    background-color: #fff; 
    letter-spacing: -.3px;
  }
  .cd-fixed-body__title{
    flex-shrink: 0;
    flex-basis: 130px;
    margin-right: 16px;
    line-height: 1.39;
    letter-spacing: -.3px;
    color: #343a40;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .cd-fixed-body__list-content{
    flex: 1 1 auto;
  }
  .cd-fixed-body__list{
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    display: flex;
    align-items: baseline;
    color: #343a40;
    margin-top: 10px;
  }
  
  
  .detail-hr{
    margin: 2rem 0;
    border-top: 1px solid #5a5a5a;
    border-bottom: 1px solid #fff;
    max-width: 100%; width: 100%;
    height: 1px;
    background-color: #e9ecef;
  }
  
  .detail-h3-example-flutter{
    margin-bottom: 10px; line-height: 1.45; color: #343a40;
    font-size: 22px; font-weight: 500; letter-spacing: -.3px;
  }
  .detail-p-example-flutter-des1{
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 4px;
    background:#3949ab;
    line-height:1.69;
    color:#fff;
    font-size:16px;
    font-weight:bold;
    text-align:center;
  }
  
  .detail-p-example-flutter-des2{
    margin-bottom: 8px; line-height: 1.69; letter-spacing: -.3px;
    color: #495057; font-size: 16px;
  }
  
  .detail-p-example-flutter-des3{
    margin-top: 28px; padding: 9.5px; border-top-right-radius: 8px; border-top-left-radius: 8px;
    background: #e7eaf8; color: #7c88d0; font-weight: bold; text-align: center;
  }
  
  
  
  .detail-right-panel{
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .detail-right-panel-container{
    position: sticky;
    top: 75px;
    margin: 28px 34px 20px auto;
    width: 332px;
  }
  
  .cd-floating__curation-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 10px 11px 16px;
    margin: 20px 0 16px;
    border: 1px solid rgba(20,150,223,.25);
    border-radius: 8px;
    background-color: #e8f5ff;
    color: #3e4042;
    overflow-wrap: break-word;
  }
  .cd-floating__curation-content{
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  
  .cd-floating__curation-content img{
    width: 38px;
    height: 38px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    -webkit-animation: flipVertical 5s infinite;
    animation: flipVertical 5s infinite;
    transition: all .5s;
  }
  .cd-floating__company-container {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  .cd-floating__company{
    position: relative;
    display: block;
    width: fit-content;
    text-align: start;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    font-weight: 700;
    animation: slideUp 5s infinite;
  }
  .cd-floating__description{
    display: block;
    text-align: start;
    line-height: 1.38;
    letter-spacing: -.3px;
    font-size: 13px;
    font-weight: 500;
  }
  .cd__discount-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    padding: 6px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 700;
    width: 100%;
    background-color: #e5503c;
    color: #fff;
  }
  .cd-floating__price{
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    padding: 20px 24px 0;
  }
  .cd-price__discount-rate{
    letter-spacing: -.3px;
    font-size: 24px;
    margin-right: 6px;
    line-height: 1.42;
    color: #e5503c;
    font-weight: 700;
  }
  .cd-floating__price h4:nth-child(2){
    letter-spacing: -.3px;
    font-size: 24px;
    margin-right: 8px;
    line-height: 1.42;
    color: #343a40;
    font-weight: 700;
  }
  .cd-price__reg-price{
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    color: #ced4da;
    font-weight: 500;
  }
  .cd-floating__buttons{
    margin-bottom: 8px;
    padding: 0 24px;
  }
  .e-enrol{
    font-weight: 700;
    min-width: 100%;
    border-color: #00c471;
    background-color: #00c471;
    border: 1px solid;
    color: #fff;
    padding: 0 16px;
    height: 48px;
    line-height: 1.47;
    font-size: 15px;
    letter-spacing: -.3px;
  }
  .e-add-cart{
    font-weight: 700;
    min-width: 100%;
    border-color: #d5dbe2;
    color: #495057;
    background-color: #fff;
    margin-top: 8px;
    border: 1px solid;
    padding: 0 16px;
    height: 48px;
    line-height: 1.47;
    font-size: 15px;
    letter-spacing: -.3px;
  }
  .detail-right-option{
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    box-shadow: 0 1px 3px 0 rgb(33 37 41 / 3%);
    background-color: #f8f9fa;
  }
  .cd-floating__sub-buttons{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 24px;
  }
  .cd-floating__sub-button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    position: relative;
    flex: auto;
    padding: 10px 12px;
    color: #495057;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }
  .cd-floating__info{
    position: relative;
    padding: 16px 24px;
    background-color: #f8f9fa;
  }
  .cd-floating__info-row{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #3e4042;
    margin-top: 4px;
  }
  .cd-floating__info-row--whetherAnswer__true div{
    width: 100%;
    margin-top: 4px;
    padding: 4px 11px;
    border-radius: 4px;
    background-color: rgba(23,92,190,.06);
    text-align: center;
    color: #175cbe;
  }
  .cd-floating__inquiry{
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 18px 20px;
    border: 1px solid #f1f3f5;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgb(33 37 41 / 3%);
  }
  .cd-floating__inquiry-header{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    color: #495057;
    font-weight: 700;
  }
  
  .cd-floating__inquiry a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    margin-left: auto;
    color: #495057;
    font-weight: 500;
    text-decoration: underline;
  }
  
  
  .card-wrapper-2{
    display: flex; flex-wrap: wrap; align-items: flex-end;
    justify-content: center; margin-top: 24px; margin-left: auto; margin-right: auto;
  }
  
  
  .card-wrapper-2 .card-el {
    width: 50%;
    flex: 1 0 auto; margin-bottom: 16px; margin-left: auto; margin-right: auto; padding: 0 8px;
  }
  .card-wrapper-2 .card-el sub{
    display: block; margin-top: 4px; text-align: center;
  }
  
  .detail-example-img1{
    display: block; margin-left: auto; margin-right: auto;
  }
  
  .detail-example-img2{
    width: 100%; height: 100%;
    margin-left: auto; margin-right: auto;
  }
  
  .detail-h3-example-flutter2{
    margin-bottom: 10px; line-height: 1.45; color: #343a40; font-size: 22px; font-weight: 500;
  }
  
  .detail-p-example-flutter-des4{
    padding-top: 10px; margin-bottom: 8px; line-height: 1.69; color: #495057; font-size: 16px;
  }
  
  .cd-curriculum{
    margin-top: 60px; margin-bottom: 8px;
  }
  
  
  
  .cd-curriculum__header{
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }
  
  .cd-curriculum__title{
    margin-right: 8px;
    line-height: 1.45;
    letter-spacing: -.3px;
    color: #343a40;
    font-size: 22px;
    font-weight: 700;
  }
  .cd-curriculum__sub-title{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    color: #adb5bd;
    font-weight: 500;
  }
  .cd-curriculum__description{
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
  }
  .cd-curriculum__description span{
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    margin-right: 12px;
    color: #495057;
    word-break: keep-all;
  }
  .cd-curriculum__description button{
    border-color: #d5dbe2;
    color: #495057;
    background-color: #fff;
    flex-shrink: 0;
    margin-left: auto;
    border: 1px solid;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    padding: 0 12px;
    height: 40px;
    line-height: 1.43;
    font-size: 14px;
    letter-spacing: -.3px;
  }
  .cd-curriculum__accordion{
    overflow: hidden;
    border: 1px solid #e9ecef;
    border-radius: 4px;
  }
  .cd-accordion__section{
    border-top: unset;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #f1f3f5;
    background-color: #f8f9fa;
    cursor: pointer;
  }
  .is-open{
    margin-right: 6px;
  }
  .is-close{
    margin-right: 6px;
  }
  
  .cd-accordion__section-title{
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    color: #343a40;
    font-weight: 700;
  }
  .cd-accordion__section-info{
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    margin-left: auto;
    color: #343a40;
  }
  .cd-accordion__unit-cover{
    overflow: hidden;
  }
  .cd-accordion__unit{
    display: flex;
    align-items: center;
    padding: 14px 20px;
  }
  .cd-accordion__unit > span:nth-child(1){
    margin-right: 8px;
    /* width: 16px;
    height: 16px; */
  }
  .ac-accordion__unit-title{
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    color: #495057;
  }
  .ac-accordion__unit-infos{
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;
  }
  .ac-accordion__unit-info--preview{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
    letter-spacing: -.3px;
    font-size: 13px;
    margin-left: 8px;
    padding: 0 7px;
    height: 26px;
    border-radius: 4px;
    border: 1px solid #00c471;
    color: #00c471;
    font-weight: 500;
  }
  .ac-accordion__unit-info--time{
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    margin-left: 16px;
    color: #343a40;
  }
  
  .cd-date__content{
    display: flex;
    justify-content: center;
    padding: 14px 0;
    border-radius: 4px;
    background-color: #f8f9fa;
    margin-bottom: 24px;
  }
  
  
  
  
  .detail-sub-course{
    background-color: #f8f9fa;
    padding-top: 48px;
    padding-bottom: 16px;
  }
  
  .sub-courses__header{
    margin-bottom: 24px;
  }
  .sub-course__content-figure{
    height: 55%;
  }
  .sub-course__content-figure img{
    width: 100%;
    height: 100%;
  }
  
  .detail-sub-course-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 54px;
  }
  .sub-courses__title{
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    line-height: 1.45;
    letter-spacing: -.3px;
    color: #343a40;
    font-size: 22px;
    font-weight: 700;
  }
  
  .sub-courses__sub-title{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    color: #adb5bd;
    font-weight: 500;
  }
  
  
  .sub-courses__courses-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;
    width: 100%;
  }
  .sub-courses__courses-list > div{
    margin-bottom: 8px; width: 50%; max-width: 50%; padding:0px 8px;
  }
  .swiper-lazy{
    width: 100%;
    height: 100%;
  }
  
  .detail-sub-course-tags{
    margin: 0.25rem 0; padding:0px;
  }
  
  .detail-sub-course-tags is-rounded:nth-child(1){
    background-color: hsl(320.16106905641357,98.61173776806642%,89.74151656750885%);
  }
  .detail-sub-course-tags is-rounded:nth-child(2){
    background-color: hsl(320.16106905641357,98.61173776806642%,89.74151656750885%);
  }
  .detail-sub-course-tags is-rounded:nth-child(3){
    background-color: hsl(320.16106905641357,98.61173776806642%,89.74151656750885%);
  }
  
  .detail-star_solid{
    width: 96.52173913043478%; white-space: nowrap; color: #fdcc11;
  }
  
  
  .course-card-item{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    align-items: flex-start;
    display: flex;
    text-align: left;
    padding: 1.25rem;
    box-shadow: 0 2px 3px rgb(0 10 18 / 10%), 0 0 0 1px rgb(0 10 18 / 10%);
  }
  .content_container{
    width: 100%;
  }
  .media-left{
    margin-right: 1rem;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .media-left figure{
    width: 8rem;
  }
  .course_title{
    font-weight: 700;
    font-size: 1.125rem;
  }
  .sub-course-tag{
    font-size: .5rem;
    border-radius: 290486px;
  }
  .course_price{
    margin-left: auto;
    text-align: right;
    min-width: 6.5rem;
  }
  .product_amount{
    font-size: 1.125rem;
    font-weight: 700;
  }
  .product_amount span{
    display: flex;
    flex-direction: column;
  }
  .product_amount del{
    opacity: .7;
    font-size: .875rem;
  }
  
  
  
  
  
  .detail-apply{
    padding-top: 16px;
    padding-bottom: 48px;
    background-color: #f8f9fa;
  }
  .detail-apply-container{
    padding: 0 54px;
    margin: 0 auto;
    max-width: 1200px;
  }
  .sub-courses__content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -26px;
  }
  .position-card{
    margin-right: 16px;
    width: calc(25% - 12px);
    margin-bottom: 48px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgb(0 10 18 / 10%), 0 0 0 1px rgb(0 10 18 / 10%);
  }
  
  .position-card__content{
    padding:10px;
  }
  
  .position-card__content-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 1.5rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.38;
    letter-spacing: -.3px;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 2px;
    color: #1b1c1d;
  }
  .position-card__content-position{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 3rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    font-weight: 500;
    color: #1b1c1d;
  }
  .position-card__content-detail{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-top: 8px;
    color: #616568;
  }
  .position-card__divider{
    display: inline-block;
    margin: 0 6px;
    width: 1px;
    height: 6px;
    border-left: 1px solid #e9ebee;
  }
  .position-card__content-duration{
    line-height: 1.38;
    letter-spacing: -.3px;
    font-size: 13px;
    font-weight: 400;
    padding-top: 4px;
    color: #858a8d;
  }





  