.CartMain{
    max-width: 1160px;
    margin: 32px auto;
}

/* 공통 */
.md-button{
    padding: 0 12px;
    height: 40px;
    line-height: 1.43;
    font-size: 14px;
    letter-spacing: -.3px;
}
.btnStyle{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.required-sign{
    color: #e5503c;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
}



/* left div */
.CartMainLeft{
    width: 100%;
    margin: 0px 32px 120px 0px;
}

.CartMainLeft_h1{
    font-weight: 700;
    color: #1b1c1d;
    padding-top: 32px;
    padding-bottom: 16px;
    font-size: 24px;
}
.CartMainLeft_empty{
    margin-top: 114px;
    text-align: center;
}
.CartMainLeft_empty p:nth-child(1){
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
}
.CartMainLeft_empty p:nth-child(2){
    padding-top: 4px;
    color: #858a8d;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
}
.CartMainLeft_empty>button{
    border-color: #00c471;
    color: #00c471;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid;
}


.total-select-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #1b1c1d;
    padding-bottom: 12px;
}

.check-input{
    width: 16px;
    height: 16px;
    background-color: #00c471;
    border-radius: 4px;
    margin-right: 4px;

}
.total-select-title-text{
    height: 22px;
    color: #1b1c1d;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 4px;
}
.total-select-title-num{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    font-weight: 400;
    padding-left: 4px;
    height: 25px;
    color: #858a8d;
}

.cart-course-card{
    display: flex;
    padding: 16px 0;
    align-items: stretch;
    user-select: none;
    border-bottom: 1px solid #f1f3f5;
}
.cart-course-card-img-div{
    margin-right: 16px;
    margin-left: 10px;
    width: 100%;
    max-width: 120px;
    min-width: 120px;
}
.cart-course-card-img{
    width: 100%;
    height: 100%;
}

.card-course-card__info{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.card-course-card__info-direct{
    color: #175cbe;
    background: #ecf2fa;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px 6px 2px;
    margin-bottom: 2px;
    max-width: 441px;
    width: min-content;
    border-radius: 4px;
}
.card-course-card__info-direct span{
    white-space: nowrap;
}

.card-course-card__info-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 3rem;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    padding-bottom: 2px;
    color: #1b1c1d;
}

.card-course-card__info-name{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
}
.card-course-card__info-name span:nth-child(1){
    color: #858a8d;
}
.card-course-card__info-name span:nth-child(2){
    color: #00c471;
}
.close{
    padding: 4px 16px 16px;
    border-right: 1px solid #f1f3f5;
}
.close-btn{
    border-color: #fff;
    color: #fff;
    background-color: inherit;
    padding: 0;
    width: 24px;
    height: 24px;
    font-weight: 700;
    border: 1px solid;
}

.cart-course-card__payment{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    min-width: 150px;
    text-align: right;
}
.cart-course-card__payment-discount-percent{
    padding-right: 4px;
    font-weight: 700;
    color: #e5503c;
}
.cart-course-card__payment-price-origin{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
    color: #abb0b5;
}
.cart-course-card__price-pay{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 16px;
    font-weight: 400;
    color: #1b1c1d;
}

/* Right */

.buyer-info-box{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    margin-bottom: 4px;
    padding: 16px 20px;
    width: 300px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background: #fff;
}

.buyer-info-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #f1f3f5;
}
.buyer-info-header-title{
    display: flex;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    color: #1b1c1d;
}
.buyer-info-header-title>span{
    margin-right: 4px;
}
.buyer-info-header-title>button{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
}


/* info content */
.buyer-info-content{
    margin-top: 10px;
}

.buyer-info-content>label{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 4px;
    color: #858a8d;
}

.buyer-info-content-input-box{
    border-color: #d5dbe2;
    width: 100%;
    padding: 0 12px;
    display: flex;
    height: 40px;
    letter-spacing: -.3px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #fff;
}
.buyer-info-content-input-box>input{
    padding: 0;
    width: 100%;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    border: 0;
    background: none;
}
.buyer-info-content-input-box>input:focus{
    outline:0;
}



.phone-numbar{
    width: 200px;
}
.phone-number>select{
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    border: none;
}


.phone-admit-btn{
    border-color: #d5dbe2;
    color: #495057;
    background-color: #fff;
    font-weight: 700;
    margin-left: 8px;
    min-width: 78px;
    border: 1px solid;
}

.coupon-num{
    color: #abb0b5;
}
.coupon-num>span{
    color: #abb0b5;
}

.voucher-input{
    text-align: right;
}


.price-regular{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: #858a8d;
    margin-top: 16px;
}


.price-discount__summary{
    display: flex;
    justify-content: space-between;
}
.price-discount-type__btn{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0;
    color: #e5503c;
    border: 0;
    background: none;
    cursor: pointer;
}
.price-discount-type__btn >span{
    margin-right: 4px;
}
.price-discount_price{
    color: #e5503c;
}


.price-pay{
    line-height: 1.47;
    letter-spacing: -.3px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 4px;
    color: #1b1c1d;
}

.pay-btn{
    border-color: #00c471;
    color: #fff;
    font-weight: 700;
    background-color: #00c471;
    margin-top: 16px;
    width: 100%;
}

.text-agreement{
    line-height: 1.5;
    letter-spacing: -.3px;
    font-size: 12px;
    font-weight: 400;
    color: #abb0b5;
    margin-top: 8px;
    margin-bottom: 4px;
}
.text-agreement >span{
    text-decoration: underline;
}


.has-questions{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 32px;
}
.has-questions >button{
    line-height: 1.43;
    letter-spacing: -.3px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    color: #858a8d;
    border: 0;
    background: none;
    cursor: pointer;
}




